import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import Cookies from "universal-cookie";

import {
  HeaderStyled,
  Logo,
  LanguageSection,
  ButtonsSection,
  MenuContainer,
  MenuContent,
  Options,
  Element,
  MenuNav,
  Item,
  LanguageContainer,
  MenuIconContainer,
  BorderGray,
  IconSection,
} from "./Header.styled";
import LogoHeader from "../../Assets/Img/logo-sendola.png";

// Own actions
import { changeLanguage } from "../../Redux/Translate";

// own states
import { State, AppDispatch } from "../../Redux/Store";

// Own constants
import { DOMAIN } from "../../Constants/Auth";

// Own Components
import Icon from "../Icon";
import Link from "../Link";
import ConstructionPopUp from "../ConstructionPopUp";
import Title from "../Title";

// Own services
import { fromAuthApp } from "../../Utilities/RedirectFromOrigin";
import { AuthSelector } from "../../Redux/Auth/Auth.slice";
import { Flex } from "../Flex";
import { PrefilledDataSelector } from "../../Redux/PrefilledData/PrefilledData.slice";
import { getSlicedString } from "../../Utilities/String";

interface HeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refToScroll?: any
}

const Header = ({refToScroll} : HeaderProps) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch<AppDispatch>();
  const [isSession, setIsSession] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(true);
  const authSesion = useSelector(AuthSelector);
  const userId = authSesion.data?.userId;
  const lang = useSelector((state: State) => state.language);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const logoutUrl = fromAuthApp("logout");
  const MenuRef = useRef<HTMLDivElement>(null);
  const LanguageRef = useRef<HTMLDivElement>(null);
  const PrefilledData = useSelector(PrefilledDataSelector);
  const userName = useMemo(
    () => PrefilledData.data?.firstName ? PrefilledData.data?.firstName : "",
    [PrefilledData]
  );

  const changeLanguageFunc = (language: string) => {
    setShowOptions(false);
    setShowMenuMobile(false);
    dispatch(changeLanguage(language));
    cookies.set("lang", language, {
      domain: DOMAIN,
    });
    i18n.changeLanguage(language);
  };

  const showMenuContainer = () => {
    setShowMenuMobile(!showMenuMobile);
  };

  const HandleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      window.location.href = logoutUrl;
    }, 500);
  };

  const handlePopUp = () => {
    setIsShowPopup(!isShowPopup);
  };

  const handleShowOptionsLanguage = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    if (!isEmpty(userId)) {
      setIsSession(true);
    }
  }, [userId]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (MenuRef.current && !MenuRef.current?.contains(event.target as Node)) {
        setShowMenuMobile(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [MenuRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (LanguageRef.current && !LanguageRef.current?.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [LanguageRef]);

  const handleBenefits = () => {
    if(refToScroll) {
      refToScroll.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }
  };

  return (
    <HeaderStyled className="header">
      <ButtonsSection ref={LanguageRef}>
        <LanguageSection onClick={handleShowOptionsLanguage}>
          {lang.language === "es" ? (
            <LanguageContainer>
              <Icon icon="mxFlag" fillIcon={false} size="medium" />
              <Title tagName="h4" text="ES" size={1} color="white" />
            </LanguageContainer>
          ) : (
            <LanguageContainer>
              <Icon icon="usaFlagCircle" fillIcon={false} size="medium" />
              <Title tagName="h4" text="EN" size={1} color="white" />
            </LanguageContainer>
          )}
          <IconSection>
            <Icon
              icon={showOptions ? "chevronUp" : "chevronDown"}
              color="white"
              fillIcon={false}
              size="medium"
            />
          </IconSection>
        </LanguageSection>
        <Options $show={showOptions}>
          <Element onClick={() => changeLanguageFunc("es")}>
            <LanguageContainer>
              <Icon icon="mxFlag" fillIcon={false} size="medium" />
              <Title tagName="h4" text="Español" size={1} color="white" />
            </LanguageContainer>
          </Element>
          <BorderGray />
          <Element onClick={() => changeLanguageFunc("en")}>
            <LanguageContainer>
              <Icon icon="usaFlagCircle" fillIcon={false} size="medium" />
              <Title tagName="h4" text="English" size={1} color="white" />
            </LanguageContainer>
          </Element>
        </Options>
      </ButtonsSection>
      <Logo>
        <Link href="/">
          <img src={LogoHeader} alt="logo-header" />
        </Link>
      </Logo>
      <MenuContainer ref={MenuRef}>
        <MenuIconContainer onClick={showMenuContainer}>
          <Flex gap={"5px"}>
            <Title  tagName="h4" text={getSlicedString(userName, 9)} size={1} color="white" />
            <Icon
              icon={showMenuMobile ? "chevronUp" : "chevronDown"}
              color="white"
              size="medium"
              fillIcon={false}
            />
          </Flex>
        </MenuIconContainer>
        <MenuContent className={showMenuMobile ? "show" : ""}>
          {isSession && (
            <MenuNav>
              <Item onClick={() => navigate("/account-details")}>
                <Icon
                  icon="edit"
                  color="white"
                  fillIcon={false}
                  size="medium"
                />
                <Title
                  tagName="h4"
                  text={t("header.ActionButtonAccount")}
                  size={1}
                  color="white"
                />
              </Item>
              <Item onClick={handleBenefits}>
                <Icon
                  icon="heart"
                  color="white"
                  fillIcon={false}
                  size="medium"
                />
                <Title
                  tagName="h4"
                  text={t("header.ActionButtonBenefits")}
                  size={1}
                  color="white"
                />
              </Item>
              <Item onClick={HandleLogout}>
                <Icon
                  icon="logout"
                  color="white"
                  fillIcon={false}
                  size="medium"
                />
                <Title
                  tagName="h4"
                  text={t("header.ActionBtnLogout")}
                  size={1}
                  color="white"
                />
              </Item>
            </MenuNav>
          )}
        </MenuContent>
      </MenuContainer>
      <ConstructionPopUp handlePopUp={handlePopUp} isPopout={isShowPopup} />
    </HeaderStyled>
  );
};

export default withTranslation("ns")(Header);
