import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { INVITE_CODE } from "../../Constants/InviteCode";

/**
 * Method to update SSN fo Veriff
 */
export const GetInvitationCodeAction = createAsyncThunk(
  INVITE_CODE,
  async (userId: string, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(
        `/rewards/invitation-code/${userId}`
      );

      return response.data?.data?.code;
    } catch (error) {
      if (error instanceof AxiosError)
        return thunkAPI.rejectWithValue(error.response?.data?.message);

      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
