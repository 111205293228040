import styled from "styled-components";
import { handleColor, handleSize } from "../../Utilities/Icons";

export const IconStyled = styled.div<{
  size: string;
  color: string;
  gradient?: boolean;
  $fill: boolean;
  width?: number;
  height?: number;
  $fillTransparent?: boolean;
}>`
  width: ${(props) => props.width ? `${props.width}px` : handleSize(props.size)};
  height: ${(props) => props.height ? `${props.height}px` : handleSize(props.size)};
  cursor: ${(props) => props.onClick ? "pointer" : "default"};

  & svg {
    width: ${(props) => props.width ? `${props.width}px` : handleSize(props.size)};
    height: ${(props) => props.height ? `${props.height}px` : handleSize(props.size)};

    rect {
      fill: ${(props) => (props.$fill ? props.theme.Primary_01 : "")};
      stroke: ${(props) =>
        props.$fill
          ? props.theme.Primary_07
          : handleColor(props.color)} !important;
    }

    circle {
      fill: ${(props) => (props.color ? handleColor(props.color) : "")};
    }

    path {
      fill: ${(props) => (props.$fillTransparent && "transparent")};
    }
  }

  & path {
    fill: ${(props) => (props.$fill ? props.theme.Primary_01 : "")};
    stroke: ${(props) => props.$fill ? props.theme.Background_01: handleColor(props.color)} !important;
  }
`;
