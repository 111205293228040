import styled, { css, CSSProperties } from "styled-components";
import { ifProp, prop, theme } from "styled-tools";

export const CardStyled = styled.div<{
  $type?: string;
}>`
  padding: 24px;
  border-radius: 20px;
  background: ${theme("Background_01")};
  box-shadow: 2px 4px 10px 0px rgba(${theme("RGBA_04")});
`;

export const GradientCard = styled.div<{
  borderWidth?: string;
  borderRadius?: CSSProperties["borderRadius"];
  padding?: CSSProperties["padding"];
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  maxWidth?: CSSProperties["maxWidth"];
  active?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  gradientBackgroundColor?: CSSProperties["backgroundColor"];
  color?: CSSProperties["color"];
}>`
  box-sizing: border-box;
  background: ${ifProp(
    "gradientBackgroundColor",
    css`linear-gradient(
        180deg,
        ${prop("gradientBackgroundColor")} 0%,
        ${prop("backgroundColor")} 100%
      )`,
    theme("Gradient_Rewards")
  )};
  padding: ${prop("borderWidth", "2px")};
  border-radius: ${prop("borderRadius", "8px")};
  width: ${prop("width")};
  max-width: ${prop("maxWidth", "100%")};
  height: ${prop("height", "100%")};
  ${ifProp(
    "active",
    css`
      box-shadow: 0px 4px 24px 0px ${theme("Black")}40;
    `,
    css`
      box-shadow: 0px 4px 24px 0px ${theme("Black")}26;
    `
  )};

  > div {
    max-width: 100%;
    box-sizing: border-box;
    border-radius: ${ifProp(
      "borderRadius",
      css`calc(${prop("borderRadius")} - 2px)`,
      "6px"
    )};
    background: ${prop("backgroundColor", "white")};
    color: ${prop("color")};
    overflow: hidden;
    padding: ${prop("padding", "3px 5px")};
    height: calc(100% - 0.5px);

    ${ifProp(
      "gradientBackgroundColor",
      css`
        background: linear-gradient(
          180deg,
          ${prop("gradientBackgroundColor")} 0%,
          ${prop("backgroundColor")} 100%
        );
      `
    )}

    * {
      color: ${prop("color")};
    }
  }
`;
