import { createSlice } from "@reduxjs/toolkit";
import { GetAmountTransactionAction } from "./GetAmountTransaction.actions";
import { State } from "../Store";
import { GetAmountTransactionState } from "../../Models/tTransactionAccount";

const initialState: GetAmountTransactionState = {
  loading: false,
  error: null,
  data: null
};

export const GetAmountTransactionSlice = createSlice({
  name: "GetAmoutTransaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAmountTransactionAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetAmountTransactionAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(GetAmountTransactionAction.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload as string;
      });
  },
});

export const GetAmountTransactionSelector = (state: State) => state.GetAmoutTransaction;
