import { useMemo } from "react";

// Own components
import Footer from "../Footer/Footer.component";
import Header from "../Header";
import Link from "../Link";
import Icon from "../Icon";
import AuthProvider from "../../Contexts/AuthProvider/AuthProvider";
import FloatingHelpButton from "../FloatingHelpButton";
import HelpLinks from "../HelpLinks";
import { FloatingCouponsButton } from "../FloatingCouponsButton/FloatingCouponsButton.component";

// Interface
import { PropsLayout } from "../../Models/Layout";

// Own styles
import {
  ContainerFluid,
  HeaderContainer,
  Content,
  FooterContainer,
  Return,
} from "./Layout.styled";
import { useEffect, useState } from "react";
import { CouponsModal } from "../CouponsModal/CouponsModal.component";
import { CouponsSelector } from "../../Redux/Coupons/Coupons.slice";
import { getCoupons } from "../../Redux/Coupons/Coupons.actions";
import { ByUserSliceSelector } from "../../Redux/ByUser/ByUser.slice";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../Redux/Store";
import { accountPaymentOrdersSelector } from "../../Redux/PaymentOrders/PaymentOrders.slice";

const Layout = (props: PropsLayout) => {
  const {
    path,
    textLink,
    show = true,
    showHelpBtn = true,
    refToScroll,
    rewardsFeature,
  } = props;

  if (!show) return;

  const dispatch = useDispatch<AppDispatch>();
  const ByUser = useSelector(ByUserSliceSelector).data;
  const { couponsGrouped } = useSelector(CouponsSelector).data;
  const [showCouponsModal, setShowCouponsModal] = useState(false);
  const paymentOrders = useSelector(accountPaymentOrdersSelector);

  const isFirstTransaction = useMemo(() => {
    if (paymentOrders.data?.results && paymentOrders.data.results.length !== 0)
      return false;

    return true;
  }, [paymentOrders]);

  useEffect(() => {
    (async () => {
      if (ByUser?.userId) {
        await dispatch(getCoupons(ByUser.userId));
      }
    })();
  }, []);

  return (
    <AuthProvider>
      <ContainerFluid $show>
        <HeaderContainer className="header">
          <Header refToScroll={refToScroll} />
        </HeaderContainer>
        <Content className="main-content">
          {(path || textLink) && (
            <Return>
              <Link href={path} size={1.5}>
                <Icon icon="circleArrowLeftGrey" size="large" />
              </Link>
            </Return>
          )}
          {props.children}
        </Content>
        {showHelpBtn && (
          <FloatingHelpButton>
            <HelpLinks />
          </FloatingHelpButton>
        )}

        {rewardsFeature && !isFirstTransaction && couponsGrouped.length > 0 && (
          <FloatingCouponsButton onClick={() => setShowCouponsModal(true)} />
        )}

        <FooterContainer className="footer">
          <Footer />
        </FooterContainer>
        <CouponsModal
          show={showCouponsModal}
          coupons={couponsGrouped}
          handleClose={() => setShowCouponsModal(false)}
        />
      </ContainerFluid>
    </AuthProvider>
  );
};

export default Layout;
