import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Own Models
import { NotificationModalProps } from "../../Models/NotificationModal";

// Own redux
import { selectorLanguage } from "../../Redux/Translate";

// Own constants
import { WhatsAppLinkEn, WhatsAppLinkEs } from "../../Constants/ConfigureApi";

// Own styles
import {
  CloseButton,
  MarginRow,
  ModalContent,
  NotificationModalContainer,
} from "./NotificationModal.styled";

// Own components
import { Column, Container, Row } from "../Grid";
import { JustifyContent } from "../../Models/Column";
import Text from "../Text";
import Button from "../Button";
import Icon from "../Icon";

const NotificationModal = (props: NotificationModalProps) => {
  const {
    show,
    modalName,
    image,
    title,
    paragraph1,
    paragraph2,
    btnText,
    handleClose,
    handleActionButton,
    hasSupport,
    hideSubmit,
    textAlignTitle = "left",
    textAlignparagraph1 = "left"
  } = props;

  const [t] = useTranslation("global");

  const lang = useSelector(selectorLanguage);
  const WhatsAppLink = lang.language === "en" ? WhatsAppLinkEn : WhatsAppLinkEs;

  const handleCloseButton = () => {
    if (handleClose) {
      handleClose();
    }
    document.body.classList.remove(modalName);
  };

  const handleActionButtonComp = () => {
    window.scrollTo(0, 0);
    document.body.classList.remove(modalName);
    handleActionButton();
  }

  useEffect(() => {
    if (show) {
      window.scrollTo(0, 0);
      document.body.classList.add(modalName);
    }
  }, [show]);

  return (
    <>
      {show && (
        <NotificationModalContainer>
          <ModalContent hasSupport={hasSupport}>
            <Container>
              <Row as={MarginRow}>
                {handleClose && (
                  <CloseButton>
                    <Icon
                      icon="cancel"
                      color="black"
                      size="medium"
                      onClick={() => handleCloseButton()}
                    />
                  </CloseButton>
                )}
                <Column span={12} justifyContent={JustifyContent.center} mt={1}>
                  <img src={image} alt="image-notification-modal" />
                </Column>
                <Column
                  span={12}
                  justifyContent={JustifyContent.flexStart}
                  my={1}
                >
                  <Text
                    color="black"
                    align={textAlignTitle}
                    weight="600"
                    margin="0"
                    size={2}
                  >
                    <span dangerouslySetInnerHTML={{ __html: title }} />
                  </Text>
                </Column>
                <Column
                  span={12}
                  justifyContent={JustifyContent.flexStart}
                  my={1}
                >
                  <Text
                    color="black"
                    align={textAlignparagraph1}
                    weight="400"
                    margin="0"
                    size={1}
                  >
                    <span dangerouslySetInnerHTML={{ __html: paragraph1 }} />
                  </Text>
                </Column>
                {paragraph2 && (
                  <Column
                    span={12}
                    justifyContent={JustifyContent.flexStart}
                    my={1}
                  >
                    <Text
                      color="black"
                      align="left"
                      weight="500"
                      margin="0"
                      size={1}
                    >
                      <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
                    </Text>
                  </Column>
                )}
                {props.children && (
                    <Column span={12} my={2}>
                      {props.children}
                    </Column>
                  )
                }
                {!hideSubmit && (
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <Button
                      type="submit"
                      variant="primary"
                      text={btnText}
                      sizeText="medium"
                      sizeButton="xlarge"
                      onClick={handleActionButtonComp}
                    />
                  </Column>
                )}
                {hasSupport && (
                  <Column span={12} justifyContent={JustifyContent.center}>
                    <Button
                      type="button"
                      variant="outline"
                      sizeButton="xlarge"
                      sizeText="medium"
                      text={t("buttons.contactSupport")}
                      onClick={() => window.location.href = WhatsAppLink}
                    />
                  </Column>
                )}
              </Row>
            </Container>
          </ModalContent>
        </NotificationModalContainer>
      )}
    </>
  );
};

export default NotificationModal;
