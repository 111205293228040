import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";
import { GET_AMOUNT_TRANSACTION } from "../../Constants/Transaction";

export const GetAmountTransactionAction = createAsyncThunk(
  GET_AMOUNT_TRANSACTION,
  async (payload: string | undefined, thunkAPI) => {
  try {
    const amount = payload ? payload : 0;
    const response = await GlobalPaymentsAPI.get(`/transactions/validate/${amount}`);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError)
      return thunkAPI.rejectWithValue(error.response?.data?.message);

    return thunkAPI.rejectWithValue(defaultErrorMessage);
  }
});
