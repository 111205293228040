import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { GET_COUPONS } from "../../Constants/Coupons";
import { Coupon, CouponGroup, CouponResponse } from "../../Models/Coupon";
import { AxiosError } from "axios";
import { defaultErrorMessage } from "../../Constants/Requests";

export const getCoupons = createAsyncThunk<
  { coupons: Coupon[]; couponsGrouped: CouponGroup[] },
  string
>(GET_COUPONS, async (userId, thunkAPI) => {
  try {
    const response = await BaseConfigApi.get<CouponResponse>(
      `/rewards/coupons/byuserid/${userId}`
    );

    const couponsGrouped = response.data.data.reduce((groups, coupon) => {
      const couponGroup = groups.find((group) => group.code === coupon.code);
      if (couponGroup) {
        couponGroup.qty = couponGroup.qty + 1;
      } else {
        groups.push({
          code: coupon.code,
          description: coupon.description,
          expirationDate: coupon.expirationDate,
          qty: 1,
        });
      }

      return groups;
    }, [] as CouponGroup[]);

    return { coupons: response.data.data, couponsGrouped };
  } catch (error) {
    if (error instanceof AxiosError)
      return thunkAPI.rejectWithValue(error.response?.data?.message);

    return thunkAPI.rejectWithValue(defaultErrorMessage);
  }
});
