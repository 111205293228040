import styled from "styled-components";
import { theme } from "styled-tools";
import Text from "../Text";

export const OverLay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  user-select: none;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 1rem;
  box-sizing: border-box;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  backdrop-filter: blur(2px);
`;

export const ModalContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 420px;
  border-radius: 10px;
  background-color: ${theme("Background_14")};
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  gap: 4px;
  color: white;
`;

export const ModalHead = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  gap: 4px;
`;

export const TextContainer = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
`;

export const RoundNumberContainer = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  background-color: ${theme("Primary_500")};
`;

export const HightlightedText = styled.span`
  font-weight: bold;
  background: ${theme("Gradient_12")};
  background-clip: text;
  margin: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HightlightStrong = styled.span`
  & > b {
    color: ${theme("Primary_03")};
  }
`;

export const TermsLink = styled(Text)`
  text-decoration: underline;
`;
