import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { defaultErrorMessage } from "../../Constants/Requests";
import { GET_SELFIE_STATUS } from "../../Constants/ProofOfLife";

export const GetSelfieStatusAction = createAsyncThunk(
  GET_SELFIE_STATUS,
  async (_, thunkAPI) => {
    try {
      const response = await BaseConfigApi.get(`/persons/full-auto-selfie`);
      return response.data.data;
    } catch(error) {
      if (error instanceof AxiosError) {
        return thunkAPI.rejectWithValue(error.response?.data.message);
      }
      return thunkAPI.rejectWithValue(defaultErrorMessage);
    }
  }
);
