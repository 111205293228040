import { useTranslation } from "react-i18next";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

// Own components
import {
  HightlightedText,
  ModalContainer,
  ModalHead,
  OverLay,
  TextContainer,
} from "./ReferralWelcomeModal.styled";
import Icon from "../Icon";
import { Flex } from "../Flex";
import Text from "../Text";
import Button from "../Button";

interface ReferralModalProps {
  show: boolean;
  handleClose: () => void;
  setVerifyIdentity?: (show: boolean) => void;
}

const ReferralWelcomeModal = ({
  show,
  setVerifyIdentity,
  handleClose,
}: ReferralModalProps) => {
  const [t] = useTranslation("global");
  const onClickClose = () => {
    if (handleClose) handleClose();
  };

  const onShare = () => {
    if (setVerifyIdentity) setVerifyIdentity(true);
    onClickClose();
  };

  return (
    <OverLay hidden={!show} onClick={onClickClose}>
      <ModalContainer onClick={(event) => event.stopPropagation()}>
        <ModalHead>
          <Icon size="medium" icon="cancel" onClick={onClickClose} />
        </ModalHead>

        <TextContainer>
          <Flex flexDirection="column" alignItems="center" gap="4px">
            <Text margin="-20px 0 0 0" size={18} color="white" weight={600}>
              {t("Rewards.welcomeBanner.title")}
            </Text>

            {show && <DotLottieReact src="/gift.lottie" autoplay />}

            <Text margin="0" size={2} color="white" weight={800} gradient>
              {t("Rewards.welcomeBanner.subTitle")}
            </Text>

            <Text margin="0" size={1} color="white">
              <HightlightedText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.welcomeBanner.paragraph1"),
                  }}
                />
              </HightlightedText>
            </Text>

            <Button
              variant="primary"
              sizeButton="xlarge"
              sizeText="medium"
              sizeIcon="medium"
              iconButton="arrowRight"
              colorIcon="white"
              text={t("Rewards.welcomeBanner.button")}
              onClick={onShare}
            />
          </Flex>
        </TextContainer>
      </ModalContainer>
    </OverLay>
  );
};

export default ReferralWelcomeModal;
