import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { GetInvitationCodeAction } from "./InvitationCode.actions";
import { GetInvitationCodeState } from "../../Models/InvitationCode";

const initialState: GetInvitationCodeState = {
  loading: false,
  error: null,
  code: null,
};

export const InvitationCodeSlice = createSlice({
  name: "invitationCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetInvitationCodeAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(GetInvitationCodeAction.fulfilled, (state, action) => {
        state.loading = false;
        state.code = action.payload;
        state.error = null;
      })
      .addCase(GetInvitationCodeAction.rejected, (state, action) => {
        state.loading = false;
        state.code = null;
        state.error = action.payload as string;
      });
  },
});

export default InvitationCodeSlice.reducer;

export const InvitationCodeSelector = (state: State) => state.invitationCode;
