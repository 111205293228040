import { createSlice } from "@reduxjs/toolkit";
import { getCoupons } from "./Coupons.actions";
import { CouponState } from "../../Models/Coupon";
import { State } from "../Store";

const initialState: CouponState = {
  loading: false,
  error: null,
  data: {
    coupons: [],
    couponsGrouped: [],
  },
};

export const CouponsSlice = createSlice({
  name: "Coupons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.data.coupons = action.payload.coupons;
        state.data.couponsGrouped = action.payload.couponsGrouped;
      })
      .addCase(getCoupons.rejected, (state, action) => {
        state.loading = false;
        state.data.coupons = [];
        state.data.couponsGrouped = [];
        state.error = action.payload as string;
      });
  },
});

export default CouponsSlice.reducer;

export const CouponsSelector = (state: State) => state.Coupons;
