import styled from "styled-components";
import { theme } from "styled-tools";
import { Flex } from "../Flex";
import Text from "../Text";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";

export const GradientContainer = styled.div`
  box-sizing: border-box;
  background: ${theme("Gradient_12")};
  border-radius: 10px;
  min-height: 92px;
  width: 100%;
  padding: 2px;
  margin: 10px 0;
  flex-grow: 0;
`;

export const ContentContainer = styled(Flex)`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: ${theme("Background_14")};

  img:nth-child(1) {
    max-width: 100px;

    ${mediaQuery[Screen.desktop]} {
      max-width: 130px;
    }
  }
`;

export const GradientText = styled(Text)`
  font-weight: bold;
  background: ${theme("Gradient_12")};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ShareButton = styled(Flex)`
  background-color: transparent;
  color: white;
  text-decoration: underline;
  border: none;
  padding: 5px 0px;
  font-weight: bold;
  width: fit-content;
`;
