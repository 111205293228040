export const TRANSACTION_DISPUTE = "transactionDispute";
export const TRANSACTION_DISPUTE_PENDING = "transactionDispute/pending";
export const TRANSACTION_DISPUTE_REJECTED = "transactionDispute/rejected";
export const TRANSACTION_DISPUTE_FULFILLED = "transactionDispute/fulfilled";

export const ACCOUNT_TRANSACTION = "GetTransactions";
export const ACCOUNT_TRANSACTION_PENDING = "GetTransactions/pending";
export const ACCOUNT_TRANSACTION_REJECTED = "GetTransactions/rejected";
export const ACCOUNT_TRANSACTION_FULFILLED = "GetTransactions/fulfilled";

export const GET_AMOUNT_TRANSACTION = "GetAmoutTransaction";
export const GET_AMOUNT_TRANSACTION_PENDING = `${GET_AMOUNT_TRANSACTION}/pending`;
export const GET_AMOUNT_TRANSACTION_REJECTED = `${GET_AMOUNT_TRANSACTION}/rejected`;
export const GET_AMOUNT_TRANSACTION_FULFILLED = `${GET_AMOUNT_TRANSACTION}/fulfilled`;