import { createAsyncThunk } from "@reduxjs/toolkit";
import { GlobalPaymentsAPI } from "../../Services/API/GlobalPaymentsApi";
import {
  GetPaymentOrdersPayload,
  PaymentOrders,
} from "../../Models/PaymentOrders";
import { PAYMENT_ORDERS } from "../../Constants/PaymentOrders";

export const getAccountPaymentOrders = createAsyncThunk(
  PAYMENT_ORDERS,
  async (payload: GetPaymentOrdersPayload, thunkAPI) => {
    try {
      const response = await GlobalPaymentsAPI.get<PaymentOrders>(
        `/PaymentOrders/Transactions`,
        {
          params: {
            Rail: payload.rail,
            Status: payload.status,
            PageNumber: payload.pageNumber,
            PageSize: payload.pageSize,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
