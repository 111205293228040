import { createSlice } from "@reduxjs/toolkit";
import { State } from "../Store";
import { getAccountPaymentOrders } from "./PaymentOrders.actions";
import { PaymentOrdersState } from "../../Models/PaymentOrders";

const initialState: PaymentOrdersState = {
  loading: false,
  error: null,
  data: null,
};

export const accountPaymentOrdersSlice = createSlice({
  name: "accountPaymentOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(getAccountPaymentOrders.pending, (state: any) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAccountPaymentOrders.fulfilled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any, action: any) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(
        getAccountPaymentOrders.rejected,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (state: any, action: any) => {
          state.loading = false;
          state.data = {};
          state.error = action.error;
        }
      );
  },
});

export const accountPaymentOrdersSelector = (state: State) =>
  state.accountPaymentOrders;
