import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseConfigApi } from "../../Services/API/BaseConfigApi";
import { SUBMIT_KYC } from "../../Constants/KYCStatus";

/**
 * Method to submit KYC
 */
export const SubmitKYCAction = createAsyncThunk(
  SUBMIT_KYC,
  async (payload:string | undefined, thunkAPI) => {
    const path = payload ? `/${payload}` : ""; 
    try {
      const response = await BaseConfigApi.post(`/persons/kyc${path}`);
      return response.data.data;
    } catch(error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);