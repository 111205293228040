import styled from "styled-components";
import mediaQuery, { Screen } from "../../Utilities/MediaQuery";
import { ifProp, prop, theme } from "styled-tools";

export const OverLay = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: ${(props) => (props.hidden ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  z-index: 1001;
  backdrop-filter: blur(2px);
`;

export const ModalWrap = styled.div`
  margin: 70px auto;
  padding: 30px;
  background: ${(props) => props.theme.Background_01};
  border: 1px solid ${(props) => props.theme.Content_State_UX_Hover};
  width: 350px;
  border-radius: 45px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mediaQuery[Screen.sm]} {
    width: 75%;
    max-width: 374px;
    padding: 45px 10px;
  }

  ${mediaQuery[Screen.mobile]} {
    width: 85%;
    max-width: 374px;
    padding: 45px 10px;
  }

  h1 {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }

  p {
    ${mediaQuery[Screen.mobile]} {
      width: 100%;
    }
  }

  button {
    max-width: 263px;
  }
`;

export const HeadModal = styled.div`
  width: 100%;
  text-align: right;
  justify-content: end;
  display: flex;

  ${mediaQuery[Screen.sm]} {
    display: none;
  }

  ${mediaQuery[Screen.mobile]} {
    display: none;
  }
`;

export const BottomModalWrapper = styled(ModalWrap)<{
  transformY?: string;
  isDragging?: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, ${prop("transformY", "0px")});
  width: 100%;
  max-width: 100%;
  margin: 0;
  border-radius: 24px 24px 0 0;
  border: none;
  padding: 24px;
  animation: slide-up ease-in 400ms;
  transition: ${ifProp("isDragging", "none", "transform 0.3s ease")};
  max-height: 100dvh;
  overflow-y: auto;
  background: ${theme("Gradient_14")};
  box-shadow: 0px -20px 24px 0px rgba(${theme("RGBA_01")}, 0.25);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme("Content_UX_01")}71;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  ${mediaQuery[Screen.desktop]} {
    max-width: 480px;
    bottom: unset;
    border-radius: 24px;
    animation: none;
  }

  @keyframes slide-up {
    0% {
      bottom: -100%;
    }
    30% {
      bottom: -70%;
    }
    40% {
      bottom: -60%;
    }
    50% {
      bottom: -50%;
    }
    70% {
      bottom: -30%;
    }
    90% {
      bottom: -10%;
    }
    100% {
      bottom: 0;
    }
  }
`;
