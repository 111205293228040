import { useTranslation, withTranslation } from "react-i18next";
import {
  ContentContainer,
  GradientContainer,
  GradientText,
  ShareButton,
} from "./ReferralBanner.styled";
import PaperPlaneImg from "../../Assets/Img/paperplane-icon.png";
import { Flex } from "../Flex";
import Text from "../Text";
import Icon from "../Icon";
import { baseURLAuthSite } from "../../Constants/ConfigureApi";

interface ReferralBannerProps {
  invitationCode: string;
}

const ReferralBanner = ({ invitationCode }: ReferralBannerProps) => {
  const [t] = useTranslation("global");

  const onShare = () => {
    if (navigator.share) {
      const shareOptions = {
        title: t("Rewards.PopUp.LinkTitle"),
        text: t("Rewards.PopUp.LinkText"),
        url: `${baseURLAuthSite}/sign-up?referral=${invitationCode}`,
      };

      navigator.share(shareOptions);
    }
  };

  return (
    <GradientContainer>
      <ContentContainer alignItems="center" flexDirection="row" gap="10px">
        <img src={PaperPlaneImg} />
        <Flex flexDirection="column" gap="4px">
          <div>
            <GradientText align="left" size={1.5} margin={0}>
              {t("Rewards.Banner.ShareCodeTitle")}
            </GradientText>
            <Text
              align="left"
              size={0.5}
              margin={0}
              color="white"
              lineHeight="12px"
            >
              {t("Rewards.Banner.ShareCodeSubtitle")}
            </Text>
          </div>

          <ShareButton gap="6px" onClick={onShare}>
            <Text size={0.5} color="white" margin={0}>
              {t("Rewards.Banner.ShareButton")}
            </Text>

            <Icon icon="share" color="white" />
          </ShareButton>
        </Flex>
      </ContentContainer>
    </GradientContainer>
  );
};

export default withTranslation("ns")(ReferralBanner);
