import { CouponsModalProps } from "../../Models/CouponsModalProps";
import Modal from "../Modal";
import { FlexContainer } from "../Flex/Flex.styled";
import {
  BorderGradient,
  CouponQty,
  CouponsModalWrapper,
} from "./CouponsModal.styled";
import Icon from "../Icon";
import Text from "../Text";
import { useTranslation } from "react-i18next";
import { GradientCard } from "../Card/Card.styled";

export const CouponsModal = (props: CouponsModalProps) => {
  const [t, i18n] = useTranslation("global");

  const lang = i18n.language;

  return (
    <Modal {...props} as={CouponsModalWrapper} hiddenCloseIcon>
      <BorderGradient>
        <FlexContainer direction="column" alignItems="center" gap="15px">
          <FlexContainer justify="space-between" gap="16px">
            <FlexContainer alignItems="center" gap="12px">
              <Icon
                icon="discountPercentFire"
                size="medium"
                onClick={() => props.handleClose && props.handleClose()}
              />
              <Text size={2} weight={600} align="left" color="white" margin={0}>
                {t("Coupons.YourCoupons")}
              </Text>
            </FlexContainer>
            <Icon
              icon="cancel"
              size="medium"
              color="white"
              onClick={() => props.handleClose && props.handleClose()}
            />
          </FlexContainer>
          <FlexContainer direction="column" gap="10px">
            {props.coupons.map((coupon) => {
              const date = new Date(coupon.expirationDate!);

              const formatted = date.toLocaleDateString(lang || "en", {
                month: "long",
                day: "numeric",
              });

              return (
                <GradientCard
                  width="100%"
                  padding="10px"
                  borderRadius="12px"
                  key={coupon.code}
                >
                  <FlexContainer justify="space-between" gap="13px">
                    <FlexContainer w="88px">
                      <Text
                        size={3}
                        weight={700}
                        align="center"
                        margin={0}
                        lineHeight="24px"
                        transform="uppercase"
                        gradient
                      >
                        {["VIVAMEX", "VIVAGUATE"].includes(coupon?.code || "")
                          ? t("Coupons.100Off")
                          : t("Coupons.WithoutCost")}
                      </Text>
                    </FlexContainer>
                    <FlexContainer direction="column" gap="7px">
                      {["VIVAMEX", "VIVAGUATE"].includes(coupon?.code || "") ? (
                        <Text
                          size={1}
                          weight={600}
                          lineHeight="20px"
                          margin={0}
                          align="left"
                          color="primary_01_700"
                        >
                          {coupon.code === "VIVAMEX"
                            ? "🎉 ¡Viva México! 🎉"
                            : "🎉 ¡Viva Guatemala! 🎉"}
                        </Text>
                      ) : (
                        <Text
                          size={0.5}
                          weight={600}
                          lineHeight="23.4px"
                          margin={0}
                          align="left"
                          color="primary_01_700"
                        >
                          {t("Coupons.SendingFee")}
                        </Text>
                      )}
                      <Text
                        size={0.5}
                        weight={600}
                        lineHeight="18.2px"
                        margin={0}
                        align="left"
                        color="pink_100"
                      >
                        {t("Coupons.Coupon")}:&nbsp;{coupon.code}
                      </Text>
                      {["VIVAMEX", "VIVAGUATE"].includes(
                        coupon?.code || ""
                      ) && (
                        <Text
                          size={0.1}
                          weight={500}
                          lineHeight="12px"
                          margin={0}
                          align="left"
                          color="pink_100"
                        >
                          {t("Coupons.Expiration")}&nbsp;{formatted}
                        </Text>
                      )}
                    </FlexContainer>
                    <div style={{ height: "100%" }}>
                      <CouponQty>x{coupon.qty}</CouponQty>
                    </div>
                  </FlexContainer>
                </GradientCard>
              );
            })}
          </FlexContainer>
        </FlexContainer>
      </BorderGradient>
    </Modal>
  );
};
