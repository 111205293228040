import { useTranslation, withTranslation } from "react-i18next";
import {
  HightlightedText,
  HightlightStrong,
  ModalContainer,
  ModalHead,
  OverLay,
  RoundNumberContainer,
  TermsLink,
  TextContainer,
} from "./ReferralModal.styled";
import PopupImageEs from "../../Assets/Img/popup-image-es.png";
import PopupImageEn from "../../Assets/Img/popup-image-en.png";
import Icon from "../Icon";
import { Flex } from "../Flex";
import Text from "../Text";
import Button from "../Button";
import { baseURLAuthSite } from "../../Constants/ConfigureApi";

interface ReferralModalProps {
  show: boolean;
  invitationCode: string;
  handleClose: () => void;
}

const ReferralModal = ({
  show,
  invitationCode,
  handleClose,
}: ReferralModalProps) => {
  const [t, i18n] = useTranslation("global");

  const lang = i18n.language;

  const onClickClose = () => {
    if (handleClose) handleClose();
  };

  const onShare = () => {
    if (navigator.share) {
      const shareOptions = {
        title: t("Rewards.PopUp.LinkTitle"),
        text: t("Rewards.PopUp.LinkText"),
        url: `${baseURLAuthSite}/sign-up?referral=${invitationCode}`,
      };

      navigator.share(shareOptions);
    }
  };

  return (
    <OverLay hidden={!show} onClick={onClickClose}>
      <ModalContainer onClick={(event) => event.stopPropagation()}>
        <ModalHead>
          <Icon size="medium" icon="cancel" onClick={onClickClose} />
        </ModalHead>
        <img
          src={lang === "es" ? PopupImageEs : PopupImageEn}
          alt="Referral program image"
          width="100%"
        />

        <TextContainer>
          <Flex flexDirection="column" alignItems="center" gap="10px">
            <Text margin="0 0 15px 0" size={1.5} color="white">
              {t("Rewards.PopUp.Title") + " "}
              <HightlightedText>
                {t("Rewards.PopUp.TitleHighlight")}
              </HightlightedText>
            </Text>

            <Flex gap="10px" width="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1.5} weight={600} color="white">
                  1
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward1"),
                  }}
                />
              </Text>
            </Flex>

            <Flex gap="10px" width="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1.5} weight={600} color="white">
                  2
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward2"),
                  }}
                />
              </Text>
            </Flex>

            <Flex gap="10px" width="100%" alignItems="center">
              <RoundNumberContainer>
                <Text margin={0} size={1.5} weight={600} color="white">
                  3
                </Text>
              </RoundNumberContainer>
              <Text
                align="left"
                margin={0}
                color="white"
                size={0.5}
                weight={300}
              >
                <HightlightStrong
                  dangerouslySetInnerHTML={{
                    __html: t("Rewards.PopUp.Reward3"),
                  }}
                />
              </Text>
            </Flex>

            <Text margin="5px" size={1.5} color="white" weight={600}>
              {t("Rewards.PopUp.Callout")}
            </Text>

            <Button
              variant="primary"
              sizeButton="xlarge"
              sizeText="medium"
              sizeIcon="medium"
              iconButtonLeft="share"
              text={t("Rewards.PopUp.Button")}
              onClick={onShare}
            />

            <Flex alignItems="center">
              <Icon icon="circleInfo" size="medium" color="white" />
              <a href={t("Rewards.TermsAndConditions")}>
                <TermsLink margin="5px" size={0} color="white">
                  {t("Rewards.PopUp.Terms")}
                </TermsLink>
              </a>
            </Flex>
          </Flex>
        </TextContainer>
      </ModalContainer>
    </OverLay>
  );
};

export default withTranslation("ns")(ReferralModal);
